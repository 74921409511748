[data-v-7309ff17] .el-step__title {
  cursor: pointer;
}
.ticketMain[data-v-7309ff17] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ticketLeft[data-v-7309ff17] {
  width: 145px;
  height: 100%;
  border-right: 1px solid #cccccc;
  background-color: #fff;
}
.ticketLeft[data-v-7309ff17] .head-layout {
  height: 46px;
  line-height: 46px;
}
.ticketRight[data-v-7309ff17] .head-layout {
  height: 46px;
  line-height: 46px;
}
.ticketStep[data-v-7309ff17] {
  width: calc(100% - 40px);
  height: 300px;
  padding: 20px;
}
.ticketRight[data-v-7309ff17] {
  width: calc(100% - 146px);
  height: 100%;
}
.ticketContent[data-v-7309ff17] {
  width: 100%;
  height: calc(100% - 46px);
  overflow: auto;
  scrollbar-width: none;
}
.ticketHead[data-v-7309ff17] {
  position: relative;
}
.ticketHeadBtn[data-v-7309ff17] {
  position: absolute;
  top: 7px;
  left: 150px;
}
.ticketHeadCode[data-v-7309ff17] {
  position: absolute;
  top: 7px;
  left: 400px;
}
.ticketForm[data-v-7309ff17] {
  padding: 12px;
  background-color: #FFFFFF;
}
.ticketGas[data-v-7309ff17] {
  margin-top: 12px;
  background-color: #FFFFFF;
}
.gasTable[data-v-7309ff17] {
  padding: 12px;
}
.ticketSecure[data-v-7309ff17] {
  margin-top: 12px;
  background-color: #FFFFFF;
}
.secureTable[data-v-7309ff17] {
  padding: 12px;
}
.ticketDisclose[data-v-7309ff17] {
  margin-top: 12px;
  background-color: #FFFFFF;
}
.ticketDisclose-no-margin[data-v-7309ff17] {
  background-color: #FFFFFF;
}
.signatureImg[data-v-7309ff17] {
  width: 70px;
  height: 32px;
  margin: auto;
}
.avatar-uploader-icon[data-v-7309ff17] {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar[data-v-7309ff17] {
  width: 178px;
  height: 178px;
  display: block;
}
.imgBox[data-v-7309ff17] {
  width: 170px;
  height: 170px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.imgBox img[data-v-7309ff17] {
  width: 100%;
  height: 100%;
}
.maskBox[data-v-7309ff17] {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #FFFFFF;
  font-size: 22px;
}
.maskBox i[data-v-7309ff17] {
  line-height: 170px;
  width: 30px;
}
.maskBox i[data-v-7309ff17]:hover {
  color: #2979FF;
}
[data-v-7309ff17] .el-textarea .el-input__count {
  height: 20px !important;
}
.riskAreaClass[data-v-7309ff17] .common_tree_handle {
  height: 100% !important;
}
.riskAreaClass[data-v-7309ff17] .common_tree_handle .el-tree {
  height: calc(100% - 110px) !important;
}